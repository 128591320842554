import MobileHome from "./components/MobileHome";

function MobileApp() {
  return (
    <>
      <MobileHome />
    </>
  );
}

export default MobileApp;
